
import 'windi.css'

// create css stylesheet
const style = document.createElement('style')
style.innerHTML = `
  .dropdown {
    zindex: 999 !important;
  }

`
document.head.appendChild(style)
